/* PC */
@media (min-width: 992px) {

  .pcDisplayNone {
    display: none;
  }

  .section {
    max-width: 992px;
    margin-bottom: 80px;
  }

  .sidebar {
    width: calc(50vw - 496px);
  }

  .linkContainer {
    width: 70vw;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
  }

  .cta-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }

  .title {
    font-size: 60px;
    border-bottom: 2px solid white;
    margin-bottom: 24px;
    font-family: 'Lora', serif;
    color: white;
  }

  .featureWrapper {
    display: flex;
  }

  .featureBoxComp {
    width: 250px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
  }

  .featureBoxComp:hover {
    width: 300px;
    height: 240px;
    transition: 0.5s;
  }

  .featureBoxComp:hover div:nth-child(1),
  .featureBoxComp:hover div:nth-child(2) {
    font-size: 120%;
    transition: 0.5s;
  }

  .featureContent {
    margin-top: 120px;
    padding: 10px;
  }

  .col3 {
    display: flex;
    justify-content: space-around;
  }

  .interviewBox:nth-child(odd) {
    flex-direction: row-reverse;
  }

  .interviewBox:nth-child(odd) .interviewPersonWrapper {
    align-items: flex-end;
    text-align: left;
  }

  .interviewBox:nth-child(odd) .interviewPersonWrapper div {
    text-align: right;
  }

  .interviewBox {
    margin-bottom: 24px;
    display: flex;
    margin-bottom: 70px;
    align-items: flex-start;
  }

  .interviewPersonWrapper {
    min-width: 300px;
    display: flex;
    flex-direction: column;
  }

  .interviewNameWrapper {
    font-size: 20px;
    font-weight: bold;
    display: inline-block;
    background-color: white;
    color: black;
    padding: 2px 24px;
    margin: 12px 0;
    width: 300px;
  }

  .interviewCompany {
    display: inline-block;
    background-color: silver;
    color: black;
    padding: 2px 10px;
    font-weight: bold;
    width: 300px;
  }

  .developerWrapper {
    display: flex;
  }

  .developerImageWrapper {
    min-width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .developerTextWrapper {
    padding: 0 40px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

}
