/* 769 ~ 991 タブレット大 */
@media (min-width: 769px) and (max-width: 991px) {
  .section {
    max-width: 700px;
    margin-bottom: 50px;
  }

  .sidebar {
    width: calc(50vw - 350px);
  }

  .featureContent {
    margin-top: 240px;
  }
}

/* 481~768 タブレット小*/
@media (min-width: 481px) and (max-width: 768px) {
  .section {
    max-width: 480px;
    margin-bottom: 50px;
  }

  .sidebar {
    width: calc(50vw - 240px);
  }

  .featureContent {
    margin-top: 200px;
  }
}

/* ~480 SP */
@media (max-width: 480px) {
  .section {
    max-width: 360px;
    margin-bottom: 50px;
  }

  .sidebar {
    width: calc(50vw - 180px);
  }

  .featureContent {
    margin-top: 140px;
  }
}

/* タブレット・SP */
/* max-width 991 */
@media (max-width: 991px) {

  .spDisplayNone {
    display: none;
  }

  .linkContainer {
    display: none;
  }

  .cta-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }

  .title {
    font-size: 2rem;
    border-bottom: 2px solid white;
    margin-bottom: 30px;
    font-family: 'Lora', serif;
    text-align: center;
    color: white;
  }

  .featureWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: center;
  }

  .featureBoxComp {
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .featureBoxComp div:nth-child(1) {
    font-size: 1.2rem;
  }

  .featureBoxComp div:nth-child(2) {
    font-size: 1rem;
  }

  .featureContent {
    width: 90%;
  }

  .spLineup {
    display: flex;
    flex-direction: column;
    color: white;
  }

  .spLineup>div:last-child {
    margin-bottom: 0;
  }

  #interview .interviewBox:nth-child(odd) {
    flex-direction: column;
  }

  #interview .interviewBox:nth-child(odd) .interviewPersonWrapper {
    align-items: flex-start;
    text-align: left;
  }

  #interview .interviewBox:nth-child(odd) .interviewPersonWrapper div {
    text-align: left;
  }

  .interviewBox {
    margin-bottom: 24px;
    display: flex;
    margin-bottom: 60px;
    flex-direction: column;
    align-items: center;
  }

  .interviewPersonWrapper {
    min-width: 30%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: flex-start;
  }

  .interviewNameWrapper {
    font-size: 20px;
    font-weight: bold;
    display: inline-block;
    background-color: white;
    color: black;
    padding: 2px 24px;
    margin: 12px 0;
  }

  .interviewCompany {
    background-color: silver;
    color: black;
    padding: 2px 24px;
    font-weight: bold;
  }

  .developerWrapper {
    display: flex;
    margin-bottom: 40px;
    flex-direction: column-reverse;
  }

  .developerImageWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .developerTextWrapper {
    padding: 20px 0;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media screen and (max-width: 991px) {

  .tb01 thead,
  .tb01 tfoot {
    display: none;
  }

  .tb01 {
    width: 100%;
  }

  .tb01 td {
    display: block;
    width: 100%;
    border-bottom: none;
    padding: 10px 0;
  }

  .tb01 td:nth-child(1) {
    background: white;
    color: black;
    font-weight: bold;
    padding: 20px 0;
  }

  .tb01 td:nth-child(4),
  .tb01 td:nth-child(5) {
    width: 50%;
    border-bottom: solid 1px #ccc;
  }

  .tb01 tr {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
}
