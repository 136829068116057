/* 全デバイス */
Text {
  color: white;
}

.header {
  background-color: white;
  width: 100%;
  height: 50px;
}

body {
  font-family: 'Noto Sans JP', sans-serif;
}

.linkContainer>a {
  color: gray;
}

main {
  display: flex;
  flex-direction: column;
  background-color: black;
  align-items: center;
}

h1,
h2,
h3,
h4 {
  color: white;
}

.col4 {
  display: flex;
}

HTML CSSResult Skip Results Iframe EDIT ON table {
  border-collapse: collapse;
  width: 100%;
}

.lora {
  font-family: 'Lora', serif;
}

/* テーブル */

table {
  border-collapse: collapse;
  width: 100%;
}

.tb01 th,
.tb01 td {
  padding: 10px;
  border: solid 1px #ccc;
  text-align: center;
  box-sizing: border-box;
}

.tb01 th {
  background: white;
  color: black;
}

/* ハンバーガーメニュー */
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 10px;
  top: 10px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #bdc3c7;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

#menu-wrap {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

#page-wrap a {
  display: block;
  color: #fff;
  font-size: 1.5rem;
  text-decoration: none;
}

.top-text-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

/* Burger.css */
.bm-item-list a {
  text-decoration: none;
  color: #000;
  padding: 10px 20px;
  display: block;
}

.bm-item-list a.highlight {
  position: relative;
  /* 相対位置を設定 */
}

.bm-item-list a .new-label {
  background-color: #ff0;
  /* 黄色の背景 */
  color: #000;
  /* 黒の文字色 */
  font-size: 12px;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 3px;
  position: absolute;
  top: 8%;
}

.title .new-label {
  background-color: #ff0;
  /* 黄色の背景 */
  color: #000;
  /* 黒の文字色 */
  font-size: 16px;
  font-weight: bold;
  padding: 2px 6px;
  border-radius: 3px;
  position: absolute;
}
